@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

input:focus,
select:focus,
textarea:focus {
  box-shadow: none !important;
}

textarea {
  resize: none;
}

body {
  font-family: "Open Sans", sans-serif !important;
}

.heading_button {
  position: sticky;
  top: 38px;
}

#navigation-menu {
  list-style: none;
  padding: 0;
  margin-top: 10px;
  margin: 10px 5px;
}

.nav-links {
  margin: 0;
  padding: 0;
}

.icon-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 12px;
}

.link_name {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}

.sub-menu {
  display: none;
  list-style: none;
  padding: 0px 13px 0 20px;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  transition: all 1s;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.sub-menu::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 95%;
  background: #00483f82;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.sub-menu li {
  padding: 2px 0;
  border-bottom: 1px solid #000;
}

.sub-menu li:last-child {
  border-bottom: none;
}

.sub-menu a {
  padding: 2px 20px;
  border-radius: 5px;
  text-decoration: none;
  color: #3c5152;
  font-weight: 500;
  font-size: 12px !important;
  display: block;
  transition: all 1s;
}

.sub-menu a i {
  margin-right: 10px;
}

.sub-menu a:hover {
  background-color: #ddd;
  color: #00483f !important;
}

.sub-menu a:focus {
  background-color: #3c5152;
  color: #fff !important;
}

main {
  display: flex;
  height: 93vh;
}

.headerdashborad {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.header h1 {
  font-size: 1.2rem;
  padding-right: 1rem;
}

li,
a {
  text-decoration: none;
}

.dashbord {
  width: 214px;
  box-shadow: 2px 1px 10px #a3aca3;
  height: 100vh;
  background-color: rgb(224, 224, 224);
}

footer button {
  border: none;
  width: 100px;
  padding: 5px;
  background-color: #00483f;
  color: #fff;
  font-weight: 500;
  margin: 0 10px 40px;
  border: 2px solid rgb(255, 255, 255) !important;
}

section {
  width: 100%;
}

.mrgin-tp {
  margin: 1rem 0;
}

.newBtn {
  margin: 0 13px;
}

.newBtn button {
  width: 70px;
  font-size: 12px;
  font-weight: 600;
  color: #fff !important;
  border: 1px solid transparent;
  background-color: #0b9159 !important;
}

.modulemain {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-body button {
  margin-top: 20px;
  border: 1px solid #0b9159;
}

.mainGrid {
  display: grid;
  place-items: center !important;
}

.loginImg img {
  margin: 0 auto;
  width: 20%;
}

.dealer-screen .header-btn button {
  margin-left: 10px;
  background-color: #0b9159;
  color: #f2f2f2;
  width: 100px;
}

.select-text-color option {
  color: black !important;
  color: #0b9159 !important;
}

.footerlogout {
  z-index: 1000 !important;
}

.heading p {
  padding-top: 0 !important;
  padding: 5px 10px;
  font-size: 15px !important;
  color: #00483f;
  padding-bottom: 2px;
  margin-top: 2rem;
  margin-bottom: 0;
  border-bottom: 1px solid #c3c3c3;
  font-weight: 700;
}

.actionBtn {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.noc-table {
  width: 100% !important;
}

.table-container tbody tr td {
  vertical-align: middle !important;
}

.tdcol {
  display: flex;
}

.thcol {
  width: 20px !important;
}

.dealer-table {
  width: 100%;
  height: 20px !important;
  padding: 0px 20px !important;
}

.header {
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 5px #5e5e5e;
}

.header h1 {
  font-weight: 700 !important;
  font-size: 1.2rem !important;
}

.footerlogoutbtn {
  position: absolute;
  bottom: 0px;
}

.reportsIcons {
  display: flex;
  gap: 5px;
  outline: none;
}

.printbtn {
  font-size: 21px;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #1a9178;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #d2d4d5;
}

.mandatoryField {
  color: #dc3545;
}

.field-dropdown select {
  font-size: 11px !important;
  padding: 1px 4px !important;
  border-radius: 3px;
}

.form-control {
  font-size: 11px !important;
  padding: 3px 4px !important;
  border-radius: 3px !important;
}

.mb-2 {
  margin-bottom: 0.4rem !important;
}

.modalbutton {
  font-size: 12px;
  padding: 4px 12px !important;
}

.tb-heading .table-filter {
  padding: 2px 3px 0 3px;
  border: 1px solid #bab2b2;
  height: 16px;
  outline: none;
}

.mainfooter {
  position: fixed;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: rgb(255, 255, 255);
  width: 100%;
  height: 35px;
  background-color: #00483f;
}

.mainfooter p {
  margin: 5px;
  font-size: 12px;
  font-weight: 600;
}

.addingButton button span {
  margin: 0 2px;
  font-weight: 600;
  color: #00483f;
  font-size: 12px;
}

.addingButtonIcon {
  cursor: pointer;
  font-size: 12px;
  color: #00483f;
  font-weight: 700;
}

.addingButton>button {
  margin-top: 6px;
  border: none;
  background: none;
  padding: 2px 6px;
}

.addingButton>button:hover {
  background-color: #e9e9e9;
  padding: 2px 6px;
  border-radius: 3px;
}

.addingButton>button:focus-visible {
  outline: none !important;
}

.pagination>.bgc:hover {
  background-color: #e9e9e9 !important;
  border-radius: 3px;
  outline: none !important;
}

.confirmation-window {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.confirmation-buttons button {
  margin: 0 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirmation-buttons button:first-child {
  background-color: #4caf50;
  color: white;
}

.confirmation-buttons button:last-child {
  background-color: #f44336;
  color: white;
}

.modal {
  --bs-modal-width: !important;
  --bs-modal-footer-gap: 0.4rem !important;
}

.mt-4 {
  margin-top: 0.7rem !important;
}

hr {
  margin: 0 -12px !important;
}

.modal-tabs .nav-item {
  flex: 0 0 auto !important;
}

.modal-tabs .nav-link {
  color: #00483f;
  font-weight: 600;
  font-size: 12px !important;
  padding: 5px 10px;
  cursor: pointer;
}

.modal-tabs .nav-link.active {
  background-color: #03581a;
  color: #ffff;
  outline: none;
}

.approval_fields1 {
  width: 13rem;
}

.approval_fields1 span {
  font-size: 13px;
}

.approval_fields2 {
  width: 26.7rem;
}

.approval_fields2 span {
  font-size: 13px;
}

#NOC_Approval {
  height: 26px;
}

#NOC_Approval_Status {
  height: 26px;
  font-size: 12px;
  padding: 0 5px;
}

#NOC_Approval_02 {
  height: 60px !important;
}

.tb-heading .af2 {
  width: 4.5rem;
}

.tb-heading .af3 {
  width: 12rem;
}

.tb-heading .af4 {
  width: 3rem;
}

.tb-heading .af1 {
  width: 7.5rem;
}

.tb-heading .af5 {
  width: 6.5rem;
}

#approval .table {
  width: 95% !important;
}

.table tbody .districtRows {
  border: none !important;
  padding: 0px !important;
}

.districtRows input {
  box-shadow: none !important;
}

.dealer_tab .nav-item {
  flex: 0 0 auto !important;
  height: 35px !important;
}

.dealer_tab {
  height: 32px !important;
}

.dealer_tab .nav-link {
  color: #333;
  font-weight: 400;
  font-size: 14px !important;
  padding: 5px 10px;
}

.dealer_tab .nav-link.active {
  background-color: #03581a;
  color: #ffff;
}

.district_grid table>thead>tr>th:nth-child(1) {
  padding-left: 13px !important;
  padding-top: 3px !important;
}

#district_Quota .table {
  width: 65% !important;
}

.districtQuota1 {
  width: 13rem;
}

.districtQuota1 span {
  font-size: 13px;
}

.districtQuota2 {
  width: 26.7rem;
}

.districtQuota2 span {
  font-size: 13px;
}

.heading {
  font-size: 30px;
  font-weight: 600;
  color: #ffffff;
  padding-left: 3px;
  background-color: #e9e9e9;
  width: 100%;
  height: 25px;
}

.modalbutton {
  display: flex;
  justify-content: left;
  align-items: center;
}

.modalbutton button {
  margin: 0 16px;
  font-size: 12px;
  font-weight: 600;
  width: 80px;
  color: #00483f !important;
  border: 1px solid #00483f !important;
}

.modal_heading span {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #ffffff;
}

.modalbutton button:hover {
  background-color: #00483f;
  color: #fff !important;
}

.modal_heading button:active {
  background-color: none !important;
  border-color: none !important;
}

.modal_header {
  background-color: #00483f;
  color: rgb(255, 255, 255);
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px !important;
  border-radius: 6px 6px 0 0px;
}

.closeicon .bn_close {
  display: flex;
  justify-content: center;
  font-size: 12px;
}

.line {
  background-color: #1a9178;
  height: 5px;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.btn-primary {
  --bs-btn-bg: none !important;
  --bs-btn-hover-bg: rgb(3, 104, 87) !important;
  --bs-btn-bg: none !important;
  --bs-btn-hover-bg: rgb(3, 104, 87) !important;
  --bs-btn-hover-border-color: none !important;
}

.bn {
  color: #1a9178;
  border-style: none;
  font-size: 16px;
}

.modal-body {
  padding: 15px 20px 8px 15px !important;
}

.formlabel {
  margin-bottom: 0 !important;
  padding-bottom: 2px;
  margin-left: 3px;
  color: #1a9178;
  font-size: 12px !important;
}

.modal-footer {
  padding: 0 6px !important;
}

.button-save {
  width: 60px;
  font-size: 12px;
  font-weight: 600;
  padding: 3px 6px;
  color: #00483f;
}

.btn:hover {
  color: #ffffff;
  background-color: #00483f;
}

.modal-content {
  width: 900px;
  margin: 0 auto !important;
}

.comboflex {
  display: flex;
  gap: 10px;
}

.table thead th {
  padding: 0 13px !important;
  background-color: #d2d4d5 !important;
  color: #00483f;
  text-align: left;
  font-size: 11px;
  padding-top: 3px !important;
  border-width: 1px !important;
  vertical-align: middle !important;
  border-bottom: 1px solid #bab2b2 !important;
}

.table tbody td {
  padding: 0 13px 0 13px !important;
  font-size: 12px !important;
}

.table tbody td button {
  padding: 0 10px !important;
}

.table .button {
  margin-right: 5px;
}

.pagination {
  padding: 0 10px !important;
  cursor: pointer !important;
  margin: 6px 14px !important;
  border-right: 1px solid #bab2b2 !important;
  color: #00483F !important;
}

.pagination .page-link {
  padding: 0 5px !important;
  background-color: transparent;
  font-size: 12px !important;
  font-weight: 600 !important;
  border: none;
  padding: 0;
  color: inherit;

  &:hover {
    color: #00483F;
  }
}

.tb-heading th {
  padding: 0 10px !important;
}

.parent_table {
  width: 100%;
}

table {
  margin-bottom: 0 !important;
  --bs-table-bg: none !important;
}

.leftarrow {
  font-size: 10px;
}

.new_pagin {
  display: flex;
  justify-content: space-between;
}

.btnPagDrp {
  display: flex;
  align-items: center;
}

.table-sm> :not(caption)>*>* {
  padding: 0.12rem 0.12rem !important;
}

#grid_table thead th {
  border-top: 1px solid #bab2b2 !important;
  padding: 2px 12px !important;
}

#SelectAll {
  vertical-align: bottom !important;
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
  font-size: 12px !important;
}

.rowperpage {
  width: 70px;
  font-size: 12px;
  font-weight: 600;
  color: #00483f;
  cursor: pointer;
  border: 1px solid #bdc3c7;
  outline: none;
  margin: 4px 0px;
}

.rowperpage * {
  color: #00483f;
  font-size: 12px;
  font-weight: 600;
}
.quotatableheight{
  max-height: 300px !important;
}
.table-container {
  display: block;
  max-height: 560px;
  width: 100%;
  border: 1px solid #bab2b2 !important;
  overflow: auto;
}


.table-container thead tr:nth-child(1) th {
  position: sticky;
  top: -1px;
}

.table-container thead tr:nth-child(2) th {
  position: sticky;
  top: 16px;
}

.confirmModal .modal-content {
  width: 550px !important;
  margin: 12.5rem auto !important;
}

.confirmModal .modal-header {
  height: 10px !important;
  font-size: 10px !important;
  background-color: rgb(5, 155, 105) !important;
}

.confirmModal .modal-header .modal-title {
  font-size: 15px !important;
  color: #fefefe !important;
}

.confirmModal .modal-header .btn-close {
  background-color: none !important;
  color: #fefefe !important;
}

.conform-msgboday .modal-body {
  height: 50px !important;
  display: flex;
  justify-content: left;
  align-items: center;
}

.SuccesModal .modal-content {
  width: 450px !important;
  margin: 12.5rem auto !important;
}

.SuccesModal .succesheader {
  letter-spacing: 1px;
  background-color: #00483f;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  color: #fefefe;
  font-size: 14px;
  padding: 5px 10px;
  font-weight: 600;
}

.SuccesModal .successmessge {
  font-size: 14px !important;
  padding: 10px 0;
  height: 55px;
  color: #00483f !important;
  font-weight: 500 !important;
}

.X .btn-close {
  filter: invert(84%) sepia(100%) saturate(1%) hue-rotate(162deg) brightness(113%) contrast(101%);
}

.dealerstatusHome>div {
  display: flex;
  align-items: center;
  margin: 4px !important;
}

.dealerstatusHome {
  margin-right: 820px;
}

.dealerstatusHome * {
  font-size: 12px;
}

.dealerstatusHome>div label {
  margin-right: 9px;
  padding-left: 7px;
  border-left: 2px solid #ddd;
  margin-left: 0px !important;
}

.dealerstatusHome>div select {
  width: 110px !important;
}

#userrights .table {
  width: 45% !important;
}

.table .userrights th {
  padding: 0 14px !important;
}

input.pw {
  -webkit-text-security: disc;
}

.headeruserform {
  display: flex;
  justify-content: space-between;
}

.leftuserrightForm {
  width: 50%;
}

.rightuserimages {
  width: 50%;
}

.img-preview {
  max-width: 100px;
  max-height: 100px;
  margin-top: 10px;
}

.image-container {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.textprefix h6 {
  font-size: 14px;
  color: #00483f;
  padding-top: 10px;
  text-align: center;
}

.image-container img {
  margin-top: 6px;
  width: 130px;
  height: 130px;
  border-radius: 6%;
  object-fit: fill;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
}

.certificate-container {
  padding: 20px;
  width: 800px;
  margin: 77rem auto 20px auto;
}

.certificate-bgimage {
  background-image: url("./Components/Reports/RegisterationReport/certificate.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
}

.content {
  font-family: sans-serif;
  line-height: 1.8;
}

.signature {
  position: relative;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  bottom: -200px;
}

.signature .left,
.signature .right {
  width: 45%;
}

.signature .right {
  text-align: right;
}

.no-date {
  display: flex;
  justify-content: space-between;
  margin-top: -44px;
}

.certContent {
  position: absolute;
  top: 33.6%;
  left: 13%;
  right: 10%;
}

.no-date {
  font-weight: 800;
}

.no-date .regDate {
  margin-right: 60px !important;
}

.no-date .regNo {
  margin-left: 25px !important;
  margin-top: -2px !important;
}

.DirectorSign {
  font-weight: 800;
  text-align: center !important;
  position: absolute;
  right: 0;
  bottom: 10px;
}

.dealar-name {
  font-size: 18px;
  font-weight: 800;
}

.dealar-name p span {
  display: inline-block;
  padding: 0 90px;
}

.subject {
  font-size: 18px;
  font-weight: 800;
}

.subject p span {
  padding-left: 30px;
  text-decoration: underline;
}

.subjectDetail {
  line-height: 34px;
  font-size: 18px;
  text-align: justify;
  margin-right: 25px;
  text-indent: 5rem;
  font-weight: 500;
}

.subjectDetail .spaceY {
  width: 10px;
}

.subjectDetail span {
  font-size: 18px;
  font-weight: 800;
}

.Dealer-details {
  font-size: 18px;
  font-weight: 800;
}

.Dealer-details p span {
  display: inline-block;
  padding: 0 1rem;
}

.table-bordered> :not(caption)>* {
  border-width: 0 !important;
}

.nocSummary_fields {
  display: flex;
  gap: 15px;
  padding: 0 16px;
}

.nocSummaryText {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

#nocSummaryCombo {
  font-size: 11px;
  height: 26px;
  padding: 0 5px;
  border-radius: 4px;
  width: 200px;
}

.generatebutton button {
  color: #00483f;
  border-radius: 4px;
  border: 1px solid #00483f;
  padding: 4px 8px;
  font-size: 11px;
  font-weight: 600;
}

.generatebutton {
  margin: 10px 18px;
}

.generatebutton button:hover {
  background-color: #00483f;
  color: #fff;
}

.NocSummaryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 30px;
  text-align: center;
}

.NocSummaryIssuance {
  padding-top: 15px;
  line-height: 10px;
}

.NocSummaryIssuance h6 {
  font-size: 16px;
  font-weight: 700;
}

.NocSummaryIssuance p {
  font-size: 13px;
}

.NocSummaryIssuance p span {
  font-weight: 600;
}

.NocSummaryContainer {
  height: auto;
  width: 100%;
  font-size: 11px;
  overflow: hidden;
  margin: 0 auto;
  padding: 0 20px 10px 20px;
}

.NocSummaryContainer h2 {
  margin: 7px 0;
  font-size: 12px;
}

.NocSummaryContainer h2 span {
  font-weight: 700;
}

.NocSummaryTable {
  width: 100%;
}

.NocSummaryTable thead {
  background-color: #f2f2f2;
}

.NocSummaryTable th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  max-width: 150px;
}

.ReportLine {
  border: 1px solid #6a6c6d;
  width: 96%;
  margin: 0 auto;
}

.NocSummaryTable td {
  font-size: 11px;
  line-height: 12px;
}

.NocSummaryHr {
  margin: 0 !important;
  border-bottom: 1px solid #6a6c6d;
}

.NocSummaryReoprt {
  padding: 18px 0;
}

.noRecordFound img {
  display: block;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

@page {
  size: auto;
  margin: 5mm 0mm;
}

.nocreportmodal {
  border-style: solid;
}

.paragraph p {
  margin-top: -20px;
  font-size: 12px;
}

.Not {
  text-align: center;
  position: relative;
  font-size: 12px;
  margin: 0 4px;
}

.header {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  padding: 0px 10px 17px 10px;
}
.leftNocLogo{
  padding: 2px 80px 0 0 ;
}
.logo2 img {
  width: 100px;
  height: 100px;
  margin-top: 0px;
  padding: 7px;
  margin-left: 100px;
}

.swiftpass {
  font-size: 15px;
  width: 20rem;
  margin-left: -3.5rem;
  color: #000;
  margin-top: 10px;
  border: 2px solid #000;
  text-shadow: none;
}

.sectionheading {
  text-align: center;
  font-weight: 800;
  font-size: 16px;
  padding: 2rem 5rem 0px;
  margin-top: -4rem;
}

.qr-container {
  text-align: right;
  text-shadow: none !important;
  color: #000 !important;
  margin: 10px 0px;
}

.qr-code {
  width: 100px !important;
  height: 90px !important;
  margin: -80px 0px 0px 0px;
}

.ReportForms {
  width: 100%;
  height: 20px;
  display: flex;
  margin: 0px 20px 10px 0px;
}

.nopermit {
  font-weight: 500;
}

.cnclin {
  width: 170px;
  border-bottom: 1.5px solid #000;
}

#dealerinput {
  display: flex;
  gap: 12px;
  font-weight: 600;
}

.nolin {
  border-bottom: 1.5px solid #000;
  flex: 1;
}

.ReportForms span {
  font-weight: 600;
  padding: 7px 10px;
}

.Report-Date p {
  margin: 0;
}

.Report-Date {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  margin-top: -19px;
  align-items: center;
}

.pararaphQR {
  display: flex;
  justify-content: row;
}

.paginationprint {
  display: flex;
  justify-content: right;
  margin-right: 30px;
}

.downlodebutton button {
  color: #016847;
  border: none;
  padding: 5px;
  background: transparent;
}

.simplereport .modal-title {
  margin-right: 695px;
  font-size: 1.1rem !important;
  color: #016847;
  font-weight: 700;
}

.summeryReport .modal-title {
  font-size: 18px !important;
  color: #016847;
  font-weight: 700;
  width: 780px;
}

.footerNoc {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 11px;
  font-weight: 600;
  margin-top: 90px;
  padding-bottom: 10px;
}

.footerright {
  width: 60%;
}

.leftright {
  width: 40%;
  text-align: center;
}

.sign {
  display: block;
  border-bottom: 1px solid black;
}

.sign img {
  width: 150px;
  height: 150px;
  padding-bottom: 10px;
}

.Report-sngleuse {
  border: 2px solid #000;
  padding: 2px 17px;
  font-size: 25px;
  position: absolute;
  right: -62px;
  top: 50%;
  bottom: 0;
  height: 42px;
  transform: rotate(-90deg);
  background: #fff;
  z-index: 11;
  font-weight: 700;
  margin: 0;
}

.nocMinReport-content {
  position: relative;
}

.additionalSignText {
  position: absolute;
  top: 49px;
  right: 0px;
  width: 253px;
  text-align: center;
}

.loginouter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: Arial, sans-serif;
  background-image: url("./Components/Login/loginBg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

#login_complete {
  padding: 20px 10px;
  margin: 0 auto;
  border: 2px solid #ffffffb0;
  background-color: #cccccc4e;
  border-radius: 16px;
  width: 23rem;
}

.login_headandimg {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login_logo img {
  width: 150px;
  padding: 10px 0 0 12px;
}

.login_heading h1 {
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: -15px;
  margin-bottom: 23px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.356);
}

.fa-eye-slash,
.fa-eye {
  position: absolute;
  right: 40px;
  font-size: 14px;
  top: 10px;
  color: #00493f;
}

.login_fields {
  width: 92%;
  margin-left: auto;
}

.input_fields {
  position: relative;
}

.input_fields .lbl {
  margin: 0 0 0 1px;
  color: rgb(255, 255, 255);
  font-weight: 400;
}

.input_fields input:focus {
  box-shadow: none;
}

.input_fields input {
  width: 90%;
  height: 35px;
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 0 40px !important;
  margin-bottom: 10px;
  box-shadow: 0.2vh 0.2vh 0.3vh #0000003d;
}

.input-icon {
  align-items: center;
  position: absolute;
  width: 35px;
  display: flex;
  height: 35px;
  background: #004b41;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  justify-content: center;
}

.input-icon i {
  color: #fff;
  font-size: 13px;
}

.login_button {
  margin: 16px 0 0 0;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_button .bttn {
  background-color: #003c20;
  padding: 6px 25px;
  box-shadow: 0.2vh 0.2vh 0.3vh #0000003d;
  border-radius: 20px;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  border: 1px solid #003c20;
  transition: 0.2s;
}

.login_button .bttn i {
  margin-left: 6px;
  font-size: 13px;
}

.login_button .bttn:hover {
  background-color: #ffffff;
  color: #003c20;
  border: 1px solid #ffffff;
}

.login_fields input {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #00493f !important;
}

#navbar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 11;
  overflow-x: hidden;
  box-shadow: 2px 2px 2px #000;
  transition: 0.2s;
  background-color: #00483f;
  color: #ffffff;
}

#navbar.open {
  width: 195px;
}

#main {
  background-color: #00483f;
  height: 38px;
  position: fixed;
  width: 100%;
  z-index: 1;
  transition: 0.2s;
}

#main.content-open {
  padding-left: 190px;
}

.menu-icon {
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 23px;
  color: #ffffff;
}

.top-bar-left-side {
  display: flex;
  align-items: center;
  height: 38px;
  padding-left: 7px;
}

.top-bar-left-side i {
  margin-right: 4px;
  font-size: 20px;
  cursor: pointer;
}

#navbar a {
  padding: 3px 8px 3px 5px;
  text-decoration: none;
  font-size: 15px;
  color: #00483f;
  display: block;
  transition: 0.3s;
}

.header-img img {
  width: 90px !important;
}

.headersidbar {
  font-weight: 700 !important;
  font-size: 21px !important;
  margin-left: 10px;
  margin-top: -15px;
  text-transform: uppercase;
}

.headersidbar p {
  margin-bottom: 0 !important;
}

.nav-links .sub-menu {
  display: block;
  font-size: 15px;
}

.menu-icon .heading {
  font-size: 16px;
  background: transparent !important;
}

.logo-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  padding: 0px 8px;
  padding-bottom: 7px;
}

.sidebar-icon-class {
  color: #fff;
  margin-right: 12px;
  font-size: 13px;
}

.active-menu-class {
  background: #fff;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid #00483f;
  pointer-events: none;
}

.active-menu-class .sidebar-icon-class {
  color: #00483f !important;
}

.active-menu-class a span {
  color: #00483f;
}

.active-menu-class a i {
  color: #00483f;
}

.active-menu-class .arrow-icons {
  color: #00483f;
}

.active-submenu-li {
  background-color: #00483f;
  color: #fff !important;
}

.user-info {
  cursor: pointer;
  height: 32px;
  padding: 3px 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  margin-right: 4px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 2px 2px 2px #000;
}

.user-info-box {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.user-ino-txt {
  margin-left: 4px;
}

.user-ino-txt h6 {
  font-size: 9px;
  margin-bottom: 0;
  color: #00483f;
  font-weight: 600;
}

.user-img {
  display: flex;
}

.user-img img {
  width: 20px;
}

.angle-down-icon-user {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.angle-down-icon-user i {
  color: #000;
  font-size: 16px;
}

.profile-box {
  width: 260px;
  background-color: #e9e9e9;
  margin: 20px;
  position: absolute;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #6b6464;
  top: 22px;
  z-index: 1;
  right: -15px;
  transition: 0.2s;
  display: none;
}

.profile-box.show {
  opacity: 1;
}

.profile-box::before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  background: #00483f;
  right: 15px;
  top: -6px;
  transform: rotate(45deg);
}

.header-prof {
  background-color: #00483f;
  color: white;
  padding: 3px 10px;
  font-size: 13px;
  font-weight: 700;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.profile-box-information {
  display: flex;
  width: 100%;
  padding: 6px;
  justify-content: center;
  align-items: center;
}

.profile-image {
  width: 60px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 4px 22px 4px 0;
}

.username {
  font-weight: 600;
  font-size: 11px;
  color: #000;
}

.logoutbutton {
  display: block;
  width: 100%;
  cursor: pointer !important;
  margin-top: 6px;
  border-radius: 20px;
  border: none;
  background: #00483f;
  font-size: 12px;
  color: #fff;
  padding: 6px 4px;
  font-weight: 700;
  box-shadow: 0px 1px 2px #6b6464;
  border: 1px solid #00483f;
}

.logoutbutton:hover {
  border: 1px solid #00483f;
  background-color: transparent;
  color: #00483f;
}

.logout-icon::before {
  content: "\1F6AA";
  margin-right: 5px;
}

@media print {
  .certificate-bgimage {
    background-image: none !important;
  }
}

/* for Noc Summery style footer */
/* Footer styling */
#nocSummeryfooter {
  background-color: #f3f3f3;
  padding: 2px 28px;
  display: flex;
  justify-content: right;
  font-weight: 550;
  /* border-top: 1px solid #ccc; */
  
  font-size: 12px;
  color: #333; /* Darker text color */
}

/* Styling for the total records and weight */
.totalRecords, .totalWeight {
  
  margin-left: 25px; /* Add some margin */
  text-align: right; /* Center align the text */
}


/* for NOC  */
.showbalance{
  /* text-align: right; */
}


.gridlabeltitle {
  margin-bottom: 0 !important;
  padding-bottom: 2px;
  margin-left: 3px;
  color: #343333;
  font-size: 12px !important;
}
.gridlabel {
  margin-bottom: 0 !important;  
  padding-bottom: 2px;    
  margin-left: 3px;    
  color: #1a9178;   
  font-size: 12px !important;
}